import React from 'react';
import { render } from 'react-dom';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import store from 'app/utils/loggedUserInfoHOC/loggedUserInfoStore';
import loggedUserInfoHOC from 'app/utils/loggedUserInfoHOC/loggedUserInfoHOC';
import AccountNameApp from './accountNameApp';

const AccountNameDesktop = () => (<div><AccountNameApp nameType="desktop" /></div>);
const AccountNameMobile = () => (<div><AccountNameApp nameType="mobile" /></div>);

const AccountNameDesktopRoot = providerHOC(store)(loggedUserInfoHOC(AccountNameDesktop));
const AccountNameMobileRoot = providerHOC(store)(loggedUserInfoHOC(AccountNameMobile));

render(<AccountNameDesktopRoot />, document.getElementById('accountNameId'));
render(<AccountNameMobileRoot />, document.getElementById('accountNameMobileId'));
